.spin {
  text-align: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.spin .spinner {
  margin: 0px;
  position: fixed;
  top: 45%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 3rem;
  height: 3rem;
}

#sidenav {
  float: left;
  width: 200px;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
}

#sidenav .rs-sidenav {
  height: 100%;
  padding-top: 1rem;
}

#wrapperspace {
  margin-left: 200px;
}

#wrapperdiv {
  overflow: initial;
}

#wrapperspace div {
  padding-left: 0.25rem;
  padding-top: 0.25rem;
  padding-right: 0.25rem;
}

#loginform {
  margin-top: 10rem;
  margin-bottom: 5rem;
}

#loginform h3 {
  text-align: center;
  margin-bottom: 2rem;
}

.fullwidthbutton {
  width: 100%;
}

.pageTitle {
  margin: 10px 0px;
}

.dashboardChartsRow .card {
  background-color: rgb(253, 253, 253);
}
.jumbotron {
  padding: 70px 30px 70px 30px;
  margin: 0px auto;
  background: black;
  color: floralwhite;
}
#custom-switch {
  width: 100px;
  height: 75px;
  /* margin-left:750px; */
  color: yellow;
}
.system {
  padding-top: -15px;
}
thead[data-test="table-foot"] {
  display: none !important;
}

.cancelimagecol {
  position: relative;
}

.cancelimagebtn {
  position: absolute;
  bottom: 0;
}
.circular--square {
  border-radius: 50%;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  margin-left: 0.1rem;
}

.imagelink {
  text-decoration: underline;
  color: "#5089bf";
  cursor: pointer;
}

.forgotpassword {
  text-decoration: underline;
  cursor: pointer;
  font-size: large;
}
